// Stimulus config
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Custom JS
import 'admin/plugins/flatpicker'
import 'admin/plugins/redactor3'
import 'admin/plugins/select2'
import 'admin/plugins/file_input'
import 'admin/plugins/copy_to_clipboard'
import 'admin/plugins/stickytable'
import 'admin/custom_layout.js'