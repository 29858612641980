import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ['row', 'showLink', 'hideLink'];

    toggle() {
        this.rowTarget.classList.toggle('is-visible');
        this.showLinkTarget.classList.toggle('is-hidden');
        this.hideLinkTarget.classList.toggle('is-visible');
    }
}