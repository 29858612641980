// Redactor3 core
import '../../redactor3/redactor'

// Plugins
import { options } from './config/redactor3'

document.addEventListener("DOMContentLoaded", startRedactor);

function startRedactor() {
    Redactor('.redactor', options)
}