(function($R)
{
    $R.add('plugin', 'imagemanager', {
        translations: {
    		en: {
    			"choose": "Choose"
    		}
        },
        init: function(app)
        {
            this.app = app;
            this.lang = app.lang;
            this.opts = app.opts;
        },
        // messages
        onmodal: {
            image: {
                open: function($modal, $form)
                {
                    if (!this.opts.imageManagerJson) return;
                    this._load($modal)
                }
            }
        },

		// private
		_load: function($modal)
		{
			var $body = $modal.getBody();

			this.$box = $R.dom('<div>');
			this.$box.attr('data-title', this.lang.get('choose'));
			this.$box.addClass('redactor-modal-tab');
			this.$box.hide();
			this.$box.css({
    			overflow: 'auto',
    			height: '300px',
    			'line-height': 1
			});

			$body.append(this.$box);

			$R.ajax.get({
        		url: this.opts.imageManagerJson,
        		success: this._parse.bind(this)
    		});
		},
		_parse: function(data)
		{
            var $div = $R.dom("<div>")

            for (var key in data)
            {
                var obj = data[key];
                if (typeof obj !== 'object') continue;

                // verify if folder
                if (obj.folder) {
                    if (obj.parent != null) continue

                    var $link = $R.dom('<a class="redactor-image-link">')
                    var $icon = $R.dom('<i class="far fa-folder redactor-image-icon">')
                    var $title = $R.dom('<p>')
                    var $hidden = $R.dom('<input>')

                    $hidden.attr("type", "hidden")
                    $hidden.val(encodeURI(JSON.stringify(obj)))

                    $link.css({
                        width: '96px',
                        margin: '0 2px 2px 0',
                        cursor: 'pointer',
                        float: 'left',
                        textAlign: 'center'
                    })

                    $title.text(obj.title)
                    $link.append($hidden).append($icon).append($title)
                    $link.on('click', this._changeFolder.bind(this))
                    $div.append($link)

                } else {

                    var $img = $R.dom('<img>');
                    var url = (obj.thumb) ? obj.thumb : obj.url;

                    $img.attr('src', url);
                    $img.attr('data-params', encodeURI(JSON.stringify(obj)));
                    $img.attr('class', 'redactor-image-img')
                    $img.css({
                        width: '96px',
                        height: '72px',
                        margin: '0 4px 2px 0',
                        cursor: 'pointer'
                    });

                    $img.on('click', this._insert.bind(this));

                    $div.append($img);

                }
            }

            this.$box.append($div)
		},
		_insert: function(e)
		{
    		e.preventDefault();

			var $el = $R.dom(e.target);
			var data = JSON.parse(decodeURI($el.attr('data-params')));

			this.app.api('module.image.insert', { image: data });
        },
        _changeFolder: function (e) {
            var $target = $R.dom(e.target)

            if ($target.children().length === 0) {
                $target = $target.parent()
            }

            var $parent = $target.parent()
            var obj = JSON.parse(decodeURI($target.find("input[type=hidden]").val()))
            var images = (typeof obj.images !== "undefined" && obj.images) ? obj.images : []
            var folders = (typeof obj.folders !== "undefined" && obj.folders) ? obj.folders : []

            // Clear box and show new images
            var that = this
            $parent.hide()

            var $div = $R.dom("<div>")
            var $link = $R.dom('<a>')
            var $icon = $R.dom('<i class="fas fa-chevron-left redactor-image-icon">')
            var $title = $R.dom('<p>')

            $link.css({
                width: '96px',
                margin: '0 2px 2px 0',
                cursor: 'pointer',
                float: 'left',
                textAlign: 'center'
            })

            $title.text("Retour")
            $link.append($icon).append($title)
            $link.on('click', this._goBack.bind(this))
            $div.append($link)

            folders.forEach(function (folder) {
                var $link = $R.dom('<a>')
                var $icon = $R.dom('<i class="far fa-folder redactor-image-icon">')
                var $title = $R.dom('<p>')
                var $hidden = $R.dom('<input>')

                $hidden.attr("type", "hidden")
                $hidden.val(encodeURI(JSON.stringify(folder)))

                $link.css({
                    width: '96px',
                    margin: '0 2px 2px 0',
                    cursor: 'pointer',
                    float: 'left',
                    textAlign: 'center'
                })

                $title.text(folder.title)
                $link.append($hidden).append($icon).append($title)
                $link.on('click', that._changeFolder.bind(that))
                $div.append($link)
            })

            images.forEach(function (image) {
                var $img = $R.dom('<img>');
                var url = (image.thumb) ? image.thumb : image.url;

                $img.attr('src', url);
                $img.attr('data-params', encodeURI(JSON.stringify(image)));
                $img.attr('class', 'redactor-image-img')
                $img.css({
                    width: '96px',
                    height: '72px',
                    margin: '0 4px 2px 0',
                    cursor: 'pointer'
                });

                $img.on('click', that._insert.bind(that));

                $div.append($img);
            })

            this.$box.append($div)
        },
        _clear: function () {
            this.$box.children().remove()
        },
        _goBack: function (e) {
            var $target = $R.dom(e.target)
            if ($target.children().length === 0) {
                $target = $target.parent()
            }

            var $parent = $target.parent()
            $parent.prev().show()
            $parent.remove()
        }
    });
})(Redactor);