// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Core
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

var jQuery = require("jquery"); 

// import jQuery from "jquery"; 
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery; 

import 'leaflet'

// import "leaflet.timeline"

// Libraries
require('bootstrap');
require('admin-lte');
require('@fortawesome/fontawesome-free/js/all.min');

require("@nathanvda/cocoon")
require("easy-autocomplete")
import "easy-autocomplete/dist/easy-autocomplete"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
 
// Custom JS
require('../admin/global')
// require('../admin/sidebar')
