import { French } from "flatpickr/dist/l10n/fr.js"
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'


export const datetimeOptions = {
    enableTime: true,
    dateFormat: "Y-m-d H:i",
    altInput: true,
    altFormat: "d/m/Y H:i",
    locale: French
}

export const dateOptions = {
    dateFormat: "Y-m-d",
    altInput: true,
    altFormat: "d/m/Y",
    locale: French
}

export const statsDateOptions = {
    dateFormat: "Y-m-d",
    altInput: true,
    altFormat: "d/m/Y",
    locale: French,
    minDate: "2021-09-01"
}

export const monthSelectPluginOptions = {
    plugins: [
        new monthSelectPlugin({
          shorthand: true, //defaults to false
          dateFormat: "m/y", //defaults to "F Y"
          altFormat: "F Y", //defaults to "F Y"
          theme: "dark" // defaults to "light"
        })
    ]
};
