// Plugins
import '../../../redactor3/plugins/custom_list'
import '../../../redactor3/plugins/ancre'
import '../../../redactor3/plugins/alignment'
import '../../../redactor3/plugins/fontsize'
import '../../../redactor3/plugins/fullscreen'
import '../../../redactor3/plugins/video'
import '../../../redactor3/plugins/table'
import '../../../redactor3/plugins/imagemanager'
import '../../../redactor3/plugins/counter'
import '../../../redactor3/plugins/properties'
import '../../../redactor3/plugins/clips'

// Translations
import '../../../redactor3/langs/fr'
import '../../../redactor3/langs/plugins/custom_list.fr'
import '../../../redactor3/langs/plugins/ancre.fr'
import '../../../redactor3/langs/plugins/alignment.fr'
import '../../../redactor3/langs/plugins/fontsize.fr'
import '../../../redactor3/langs/plugins/fullscreen.fr'
import '../../../redactor3/langs/plugins/video.fr'
import '../../../redactor3/langs/plugins/table.fr'
import '../../../redactor3/langs/plugins/imagemanager.fr'
import '../../../redactor3/langs/plugins/counter.fr'
import '../../../redactor3/langs/plugins/properties.fr'
import '../../../redactor3/langs/plugins/clips.fr'

const csrf_token = $('meta[name=csrf-token]').attr('content');
const csrf_param = $('meta[name=csrf-param]').attr('content');
let params;

if (csrf_param !== undefined && csrf_token !== undefined) {
    params = csrf_param + "=" + encodeURIComponent(csrf_token);
}

export const options = {
    buttons: ['html', 'format', 'bold', 'italic', 'deleted', 'image', 'file', 'link'],
    formatting: ['p', 'blockquote', 'pre', 'h2', 'h3', 'h4', 'h5', 'h6'],
    imagePosition : true,
    imageResizable: true,
    removeScript: false,
    linkNewTab: true,
    imageUpload: "/admin/images",
    imageUploadFields: params,
    imageManagerJson: '/admin/images.json',
    imageUploadParam: 'redactor_file',
    multipleUpload: false,
    imageData: {
        authenticity_token: csrf_token
    },
    callbacks: {
        image: {
            uploaded: function (image, response) {
                $R.dom(image)
                    .find("img")
                    .addClass("img-fluid")
                    .css("display", "inline-block")
            },
            changed: function (image) {
                $R.dom(image)
                    .find("img")
                    .addClass("img-fluid")
                    .css("display", "inline-block")
            },
            inserted: function (image) {
                $R.dom(image)
                    .find("img")
                    .addClass("img-fluid")
                    .css("display", "inline-block")
            }
        }
    },
    path: "/assets/redactor-rails",
    css: "style.css",
    lang: 'fr',
    linkify: false,
    minHeight: '300px',
    plugins: [
        'custom_list',
        'ancre',
        'alignment',
        'fontsize',
        'fullscreen',
        'video',
        'table',
        'imagemanager',
        'counter',
        'properties',
        'clips'
    ],
    clips: [
        ["&#8986", "&#8986"],
        ["&#8987", "&#8987"],
        ["&#9193", "&#9193"],
        ["&#9194", "&#9194"],
        ["&#9195", "&#9195"],
        ["&#9196", "&#9196"],
        ["&#9197", "&#9197"],
        ["&#9198", "&#9198"],
        ["&#9199", "&#9199"],
        ["&#9200", "&#9200"],
        ["&#9201", "&#9201"],
        ["&#9202", "&#9202"],
        ["&#9203", "&#9203"],
        ["&#9208", "&#9208"],
        ["&#9209", "&#9209"],
        ["&#9210", "&#9210"],
        ["&#9410", "&#9410"],
        ["&#9748", "&#9748"],
        ["&#9749", "&#9749"],
        ["&#9757", "&#9757"],
        ["&#9800", "&#9800"],
        ["&#9801", "&#9801"],
        ["&#9802", "&#9802"],
        ["&#9803", "&#9803"],
        ["&#9804", "&#9804"],
        ["&#9805", "&#9805"],
        ["&#9806", "&#9806"],
        ["&#9807", "&#9807"],
        ["&#9808", "&#9808"],
        ["&#9809", "&#9809"],
        ["&#9810", "&#9810"],
        ["&#9811", "&#9811"],
        ["&#9823", "&#9823"],
        ["&#9855", "&#9855"],
        ["&#9875", "&#9875"],
        ["&#9889", "&#9889"],
        ["&#9898", "&#9898"],
        ["&#9899", "&#9899"],
        ["&#9917", "&#9917"],
        ["&#9918", "&#9918"],
        ["&#9924", "&#9924"],
        ["&#9925", "&#9925"],
        ["&#9934", "&#9934"],
        ["&#9935", "&#9935"],
        ["&#9937", "&#9937"],
        ["&#9939", "&#9939"],
        ["&#9940", "&#9940"],
        ["&#9961", "&#9961"],
        ["&#9962", "&#9962"],
        ["&#9968", "&#9968"],
        ["&#9969", "&#9969"],
        ["&#9970", "&#9970"],
        ["&#9971", "&#9971"],
        ["&#9972", "&#9972"],
        ["&#9973", "&#9973"],
        ["&#9975", "&#9975"],
        ["&#9976", "&#9976"],
        ["&#9977", "&#9977"],
        ["&#9978", "&#9978"],
        ["&#9981", "&#9981"],
        ["&#9986", "&#9986"],
        ["&#9989", "&#9989"],
        ["&#9992", "&#9992"],
        ["&#9993", "&#9993"],
        ["&#9994", "&#9994"],
        ["&#9995", "&#9995"],
        ["&#9996", "&#9996"],
        ["&#9997", "&#9997"],
        ["&#9999", "&#9999"],
        ["&#10002", "&#10002"],
        ["&#10004", "&#10004"],
        ["&#10006", "&#10006"],
        ["&#10013", "&#10013"],
        ["&#10017", "&#10017"],
        ["&#10024", "&#10024"],
        ["&#10035", "&#10035"],
        ["&#10036", "&#10036"],
        ["&#10052", "&#10052"],
        ["&#10055", "&#10055"],
        ["&#10060", "&#10060"],
        ["&#10062", "&#10062"],
        ["&#10067", "&#10067"],
        ["&#10068", "&#10068"],
        ["&#10069", "&#10069"],
        ["&#10071", "&#10071"],
        ["&#10083", "&#10083"],
        ["&#10084", "&#10084"],
        ["&#10133", "&#10133"],
        ["&#10134", "&#10134"],
        ["&#10135", "&#10135"],
        ["&#10145", "&#10145"],
        ["&#10160", "&#10160"],
        ["&#10175", "&#10175"],
        ["&#10548", "&#10548"],
        ["&#10549", "&#10549"],
        ["&#11013", "&#11013"],
        ["&#11014", "&#11014"],
        ["&#11015", "&#11015"],
        ["&#11035", "&#11035"],
        ["&#11036", "&#11036"],
        ["&#11088", "&#11088"],
        ["&#11093", "&#11093"],
        ["&#12336", "&#12336"],
        ["&#12349", "&#12349"],
        ["&#12951", "&#12951"],
        ["&#12953", "&#12953"],
        ["&#126980", "&#126980"],
        ["&#127183", "&#127183"],
        ["&#127344", "&#127344"],
        ["&#127345", "&#127345"],
        ["&#127358", "&#127358"],
        ["&#127359", "&#127359"],
        ["&#127374", "&#127374"],
        ["&#127377", "&#127377"],
        ["&#127378", "&#127378"],
        ["&#127379", "&#127379"],
        ["&#127380", "&#127380"],
        ["&#127381", "&#127381"],
        ["&#127382", "&#127382"],
        ["&#127383", "&#127383"],
        ["&#127384", "&#127384"],
        ["&#127385", "&#127385"],
        ["&#127386", "&#127386"],
        ["&#127489", "&#127489"],
        ["&#127490", "&#127490"],
        ["&#127514", "&#127514"],
        ["&#127535", "&#127535"],
        ["&#127538", "&#127538"],
        ["&#127539", "&#127539"],
        ["&#127540", "&#127540"],
        ["&#127541", "&#127541"],
        ["&#127542", "&#127542"],
        ["&#127543", "&#127543"],
        ["&#127544", "&#127544"],
        ["&#127545", "&#127545"],
        ["&#127546", "&#127546"],
        ["&#127568", "&#127568"],
        ["&#127569", "&#127569"],
        ["&#127744", "&#127744"],
        ["&#127745", "&#127745"],
        ["&#127746", "&#127746"],
        ["&#127747", "&#127747"],
        ["&#127748", "&#127748"],
        ["&#127749", "&#127749"],
        ["&#127750", "&#127750"],
        ["&#127751", "&#127751"],
        ["&#127752", "&#127752"],
        ["&#127753", "&#127753"],
        ["&#127754", "&#127754"],
        ["&#127755", "&#127755"],
        ["&#127756", "&#127756"],
        ["&#127757", "&#127757"],
        ["&#127758", "&#127758"],
        ["&#127759", "&#127759"],
        ["&#127760", "&#127760"],
        ["&#127761", "&#127761"],
        ["&#127762", "&#127762"],
        ["&#127763", "&#127763"],
        ["&#127764", "&#127764"],
        ["&#127765", "&#127765"],
        ["&#127766", "&#127766"],
        ["&#127767", "&#127767"],
        ["&#127768", "&#127768"],
        ["&#127769", "&#127769"],
        ["&#127770", "&#127770"],
        ["&#127771", "&#127771"],
        ["&#127772", "&#127772"],
        ["&#127773", "&#127773"],
        ["&#127774", "&#127774"],
        ["&#127775", "&#127775"],
        ["&#127776", "&#127776"],
        ["&#127777", "&#127777"],
        ["&#127780", "&#127780"],
        ["&#127781", "&#127781"],
        ["&#127782", "&#127782"],
        ["&#127783", "&#127783"],
        ["&#127784", "&#127784"],
        ["&#127785", "&#127785"],
        ["&#127786", "&#127786"],
        ["&#127787", "&#127787"],
        ["&#127788", "&#127788"],
        ["&#127789", "&#127789"],
        ["&#127790", "&#127790"],
        ["&#127791", "&#127791"],
        ["&#127792", "&#127792"],
        ["&#127793", "&#127793"],
        ["&#127794", "&#127794"],
        ["&#127795", "&#127795"],
        ["&#127796", "&#127796"],
        ["&#127797", "&#127797"],
        ["&#127798", "&#127798"],
        ["&#127799", "&#127799"],
        ["&#127800", "&#127800"],
        ["&#127801", "&#127801"],
        ["&#127802", "&#127802"],
        ["&#127803", "&#127803"],
        ["&#127804", "&#127804"],
        ["&#127805", "&#127805"],
        ["&#127806", "&#127806"],
        ["&#127807", "&#127807"],
        ["&#127808", "&#127808"],
        ["&#127809", "&#127809"],
        ["&#127810", "&#127810"],
        ["&#127811", "&#127811"],
        ["&#127812", "&#127812"],
        ["&#127813", "&#127813"],
        ["&#127814", "&#127814"],
        ["&#127815", "&#127815"],
        ["&#127816", "&#127816"],
        ["&#127817", "&#127817"],
        ["&#127818", "&#127818"],
        ["&#127819", "&#127819"],
        ["&#127820", "&#127820"],
        ["&#127821", "&#127821"],
        ["&#127822", "&#127822"],
        ["&#127823", "&#127823"],
        ["&#127824", "&#127824"],
        ["&#127825", "&#127825"],
        ["&#127826", "&#127826"],
        ["&#127827", "&#127827"],
        ["&#127828", "&#127828"],
        ["&#127829", "&#127829"],
        ["&#127830", "&#127830"],
        ["&#127831", "&#127831"],
        ["&#127832", "&#127832"],
        ["&#127833", "&#127833"],
        ["&#127834", "&#127834"],
        ["&#127835", "&#127835"],
        ["&#127836", "&#127836"],
        ["&#127837", "&#127837"],
        ["&#127838", "&#127838"],
        ["&#127839", "&#127839"],
        ["&#127840", "&#127840"],
        ["&#127841", "&#127841"],
        ["&#127842", "&#127842"],
        ["&#127843", "&#127843"],
        ["&#127844", "&#127844"],
        ["&#127845", "&#127845"],
        ["&#127846", "&#127846"],
        ["&#127847", "&#127847"],
        ["&#127848", "&#127848"],
        ["&#127849", "&#127849"],
        ["&#127850", "&#127850"],
        ["&#127851", "&#127851"],
        ["&#127852", "&#127852"],
        ["&#127853", "&#127853"],
        ["&#127854", "&#127854"],
        ["&#127855", "&#127855"],
        ["&#127856", "&#127856"],
        ["&#127857", "&#127857"],
        ["&#127858", "&#127858"],
        ["&#127859", "&#127859"],
        ["&#127860", "&#127860"],
        ["&#127861", "&#127861"],
        ["&#127862", "&#127862"],
        ["&#127863", "&#127863"],
        ["&#127864", "&#127864"],
        ["&#127865", "&#127865"],
        ["&#127866", "&#127866"],
        ["&#127867", "&#127867"],
        ["&#127868", "&#127868"],
        ["&#127869", "&#127869"],
        ["&#127870", "&#127870"],
        ["&#127871", "&#127871"],
        ["&#127872", "&#127872"],
        ["&#127873", "&#127873"],
        ["&#127874", "&#127874"],
        ["&#127875", "&#127875"],
        ["&#127876", "&#127876"],
        ["&#127877", "&#127877"],
        ["&#127878", "&#127878"],
        ["&#127879", "&#127879"],
        ["&#127880", "&#127880"],
        ["&#127881", "&#127881"],
        ["&#127882", "&#127882"],
        ["&#127883", "&#127883"],
        ["&#127884", "&#127884"],
        ["&#127885", "&#127885"],
        ["&#127886", "&#127886"],
        ["&#127887", "&#127887"],
        ["&#127888", "&#127888"],
        ["&#127889", "&#127889"],
        ["&#127890", "&#127890"],
        ["&#127891", "&#127891"],
        ["&#127894", "&#127894"],
        ["&#127895", "&#127895"],
        ["&#127897", "&#127897"],
        ["&#127898", "&#127898"],
        ["&#127899", "&#127899"],
        ["&#127902", "&#127902"],
        ["&#127903", "&#127903"],
        ["&#127904", "&#127904"],
        ["&#127905", "&#127905"],
        ["&#127906", "&#127906"],
        ["&#127907", "&#127907"],
        ["&#127908", "&#127908"],
        ["&#127909", "&#127909"],
        ["&#127910", "&#127910"],
        ["&#127911", "&#127911"],
        ["&#127912", "&#127912"],
        ["&#127913", "&#127913"],
        ["&#127914", "&#127914"],
        ["&#127915", "&#127915"],
        ["&#127916", "&#127916"],
        ["&#127917", "&#127917"],
        ["&#127918", "&#127918"],
        ["&#127919", "&#127919"],
        ["&#127920", "&#127920"],
        ["&#127921", "&#127921"],
        ["&#127922", "&#127922"],
        ["&#127923", "&#127923"],
        ["&#127924", "&#127924"],
        ["&#127925", "&#127925"],
        ["&#127926", "&#127926"],
        ["&#127927", "&#127927"],
        ["&#127928", "&#127928"],
        ["&#127929", "&#127929"],
        ["&#127930", "&#127930"],
        ["&#127931", "&#127931"],
        ["&#127932", "&#127932"],
        ["&#127933", "&#127933"],
        ["&#127934", "&#127934"],
        ["&#127935", "&#127935"],
        ["&#127936", "&#127936"],
        ["&#127937", "&#127937"],
        ["&#127938", "&#127938"],
        ["&#127939", "&#127939"],
        ["&#127940", "&#127940"],
        ["&#127941", "&#127941"],
        ["&#127942", "&#127942"],
        ["&#127943", "&#127943"],
        ["&#127944", "&#127944"],
        ["&#127945", "&#127945"],
        ["&#127946", "&#127946"],
        ["&#127947", "&#127947"],
        ["&#127948", "&#127948"],
        ["&#127949", "&#127949"],
        ["&#127950", "&#127950"],
        ["&#127951", "&#127951"],
        ["&#127952", "&#127952"],
        ["&#127953", "&#127953"],
        ["&#127954", "&#127954"],
        ["&#127955", "&#127955"],
        ["&#127956", "&#127956"],
        ["&#127957", "&#127957"],
        ["&#127958", "&#127958"],
        ["&#127959", "&#127959"],
        ["&#127960", "&#127960"],
        ["&#127961", "&#127961"],
        ["&#127962", "&#127962"],
        ["&#127963", "&#127963"],
        ["&#127964", "&#127964"],
        ["&#127965", "&#127965"],
        ["&#127966", "&#127966"],
        ["&#127967", "&#127967"],
        ["&#127968", "&#127968"],
        ["&#127969", "&#127969"],
        ["&#127970", "&#127970"],
        ["&#127971", "&#127971"],
        ["&#127972", "&#127972"],
        ["&#127973", "&#127973"],
        ["&#127974", "&#127974"],
        ["&#127975", "&#127975"],
        ["&#127976", "&#127976"],
        ["&#127977", "&#127977"],
        ["&#127978", "&#127978"],
        ["&#127979", "&#127979"],
        ["&#127980", "&#127980"],
        ["&#127981", "&#127981"],
        ["&#127982", "&#127982"],
        ["&#127983", "&#127983"],
        ["&#127984", "&#127984"],
        ["&#127987", "&#127987"],
        ["&#127988", "&#127988"],
        ["&#127989", "&#127989"],
        ["&#127991", "&#127991"],
        ["&#127992", "&#127992"],
        ["&#127993", "&#127993"],
        ["&#127994", "&#127994"],
        ["&#127995", "&#127995"],
        ["&#127996", "&#127996"],
        ["&#127997", "&#127997"],
        ["&#127998", "&#127998"],
        ["&#127999", "&#127999"],
        ["&#128000", "&#128000"],
        ["&#128001", "&#128001"],
        ["&#128002", "&#128002"],
        ["&#128003", "&#128003"],
        ["&#128004", "&#128004"],
        ["&#128005", "&#128005"],
        ["&#128006", "&#128006"],
        ["&#128007", "&#128007"],
        ["&#128008", "&#128008"],
        ["&#128009", "&#128009"],
        ["&#128010", "&#128010"],
        ["&#128011", "&#128011"],
        ["&#128012", "&#128012"],
        ["&#128013", "&#128013"],
        ["&#128014", "&#128014"],
        ["&#128015", "&#128015"],
        ["&#128016", "&#128016"],
        ["&#128017", "&#128017"],
        ["&#128018", "&#128018"],
        ["&#128019", "&#128019"],
        ["&#128020", "&#128020"],
        ["&#128021", "&#128021"],
        ["&#128022", "&#128022"],
        ["&#128023", "&#128023"],
        ["&#128024", "&#128024"],
        ["&#128025", "&#128025"],
        ["&#128026", "&#128026"],
        ["&#128027", "&#128027"],
        ["&#128028", "&#128028"],
        ["&#128029", "&#128029"],
        ["&#128030", "&#128030"],
        ["&#128031", "&#128031"],
        ["&#128032", "&#128032"],
        ["&#128033", "&#128033"],
        ["&#128034", "&#128034"],
        ["&#128035", "&#128035"],
        ["&#128036", "&#128036"],
        ["&#128037", "&#128037"],
        ["&#128038", "&#128038"],
        ["&#128039", "&#128039"],
        ["&#128040", "&#128040"],
        ["&#128041", "&#128041"],
        ["&#128042", "&#128042"],
        ["&#128043", "&#128043"],
        ["&#128044", "&#128044"],
        ["&#128045", "&#128045"],
        ["&#128046", "&#128046"],
        ["&#128047", "&#128047"],
        ["&#128048", "&#128048"],
        ["&#128049", "&#128049"],
        ["&#128050", "&#128050"],
        ["&#128051", "&#128051"],
        ["&#128052", "&#128052"],
        ["&#128053", "&#128053"],
        ["&#128054", "&#128054"],
        ["&#128055", "&#128055"],
        ["&#128056", "&#128056"],
        ["&#128057", "&#128057"],
        ["&#128058", "&#128058"],
        ["&#128059", "&#128059"],
        ["&#128060", "&#128060"],
        ["&#128061", "&#128061"],
        ["&#128062", "&#128062"],
        ["&#128063", "&#128063"],
        ["&#128064", "&#128064"],
        ["&#128065", "&#128065"],
        ["&#128066", "&#128066"],
        ["&#128067", "&#128067"],
        ["&#128068", "&#128068"],
        ["&#128069", "&#128069"],
        ["&#128070", "&#128070"],
        ["&#128071", "&#128071"],
        ["&#128072", "&#128072"],
        ["&#128073", "&#128073"],
        ["&#128074", "&#128074"],
        ["&#128075", "&#128075"],
        ["&#128076", "&#128076"],
        ["&#128077", "&#128077"],
        ["&#128078", "&#128078"],
        ["&#128079", "&#128079"],
        ["&#128080", "&#128080"],
        ["&#128081", "&#128081"],
        ["&#128082", "&#128082"],
        ["&#128083", "&#128083"],
        ["&#128084", "&#128084"],
        ["&#128085", "&#128085"],
        ["&#128086", "&#128086"],
        ["&#128087", "&#128087"],
        ["&#128088", "&#128088"],
        ["&#128089", "&#128089"],
        ["&#128090", "&#128090"],
        ["&#128091", "&#128091"],
        ["&#128092", "&#128092"],
        ["&#128093", "&#128093"],
        ["&#128094", "&#128094"],
        ["&#128095", "&#128095"],
        ["&#128096", "&#128096"],
        ["&#128097", "&#128097"],
        ["&#128098", "&#128098"],
        ["&#128099", "&#128099"],
        ["&#128100", "&#128100"],
        ["&#128101", "&#128101"],
        ["&#128102", "&#128102"],
        ["&#128103", "&#128103"],
        ["&#128104", "&#128104"],
        ["&#128105", "&#128105"],
        ["&#128106", "&#128106"],
        ["&#128107", "&#128107"],
        ["&#128108", "&#128108"],
        ["&#128109", "&#128109"],
        ["&#128110", "&#128110"],
        ["&#128111", "&#128111"],
        ["&#128112", "&#128112"],
        ["&#128113", "&#128113"],
        ["&#128114", "&#128114"],
        ["&#128115", "&#128115"],
        ["&#128116", "&#128116"],
        ["&#128117", "&#128117"],
        ["&#128118", "&#128118"],
        ["&#128119", "&#128119"],
        ["&#128120", "&#128120"],
        ["&#128121", "&#128121"],
        ["&#128122", "&#128122"],
        ["&#128123", "&#128123"],
        ["&#128124", "&#128124"],
        ["&#128125", "&#128125"],
        ["&#128126", "&#128126"],
        ["&#128127", "&#128127"],
        ["&#128128", "&#128128"],
        ["&#128129", "&#128129"],
        ["&#128130", "&#128130"],
        ["&#128131", "&#128131"],
        ["&#128132", "&#128132"],
        ["&#128133", "&#128133"],
        ["&#128134", "&#128134"],
        ["&#128135", "&#128135"],
        ["&#128136", "&#128136"],
        ["&#128137", "&#128137"],
        ["&#128138", "&#128138"],
        ["&#128139", "&#128139"],
        ["&#128140", "&#128140"],
        ["&#128141", "&#128141"],
        ["&#128142", "&#128142"],
        ["&#128143", "&#128143"],
        ["&#128144", "&#128144"],
        ["&#128145", "&#128145"],
        ["&#128146", "&#128146"],
        ["&#128147", "&#128147"],
        ["&#128148", "&#128148"],
        ["&#128149", "&#128149"],
        ["&#128150", "&#128150"],
        ["&#128151", "&#128151"],
        ["&#128152", "&#128152"],
        ["&#128153", "&#128153"],
        ["&#128154", "&#128154"],
        ["&#128155", "&#128155"],
        ["&#128156", "&#128156"],
        ["&#128157", "&#128157"],
        ["&#128158", "&#128158"],
        ["&#128159", "&#128159"],
        ["&#128160", "&#128160"],
        ["&#128161", "&#128161"],
        ["&#128162", "&#128162"],
        ["&#128163", "&#128163"],
        ["&#128164", "&#128164"],
        ["&#128165", "&#128165"],
        ["&#128166", "&#128166"],
        ["&#128167", "&#128167"],
        ["&#128168", "&#128168"],
        ["&#128169", "&#128169"],
        ["&#128170", "&#128170"],
        ["&#128171", "&#128171"],
        ["&#128172", "&#128172"],
        ["&#128173", "&#128173"],
        ["&#128174", "&#128174"],
        ["&#128175", "&#128175"],
        ["&#128176", "&#128176"],
        ["&#128177", "&#128177"],
        ["&#128178", "&#128178"],
        ["&#128179", "&#128179"],
        ["&#128180", "&#128180"],
        ["&#128181", "&#128181"],
        ["&#128182", "&#128182"],
        ["&#128183", "&#128183"],
        ["&#128184", "&#128184"],
        ["&#128185", "&#128185"],
        ["&#128186", "&#128186"],
        ["&#128187", "&#128187"],
        ["&#128188", "&#128188"],
        ["&#128189", "&#128189"],
        ["&#128190", "&#128190"],
        ["&#128191", "&#128191"],
        ["&#128192", "&#128192"],
        ["&#128193", "&#128193"],
        ["&#128194", "&#128194"],
        ["&#128195", "&#128195"],
        ["&#128196", "&#128196"],
        ["&#128197", "&#128197"],
        ["&#128198", "&#128198"],
        ["&#128199", "&#128199"],
        ["&#128200", "&#128200"],
        ["&#128201", "&#128201"],
        ["&#128202", "&#128202"],
        ["&#128203", "&#128203"],
        ["&#128204", "&#128204"],
        ["&#128205", "&#128205"],
        ["&#128206", "&#128206"],
        ["&#128207", "&#128207"],
        ["&#128208", "&#128208"],
        ["&#128209", "&#128209"],
        ["&#128210", "&#128210"],
        ["&#128211", "&#128211"],
        ["&#128212", "&#128212"],
        ["&#128213", "&#128213"],
        ["&#128214", "&#128214"],
        ["&#128215", "&#128215"],
        ["&#128216", "&#128216"],
        ["&#128217", "&#128217"],
        ["&#128218", "&#128218"],
        ["&#128219", "&#128219"],
        ["&#128220", "&#128220"],
        ["&#128221", "&#128221"],
        ["&#128222", "&#128222"],
        ["&#128223", "&#128223"],
        ["&#128224", "&#128224"],
        ["&#128225", "&#128225"],
        ["&#128226", "&#128226"],
        ["&#128227", "&#128227"],
        ["&#128228", "&#128228"],
        ["&#128229", "&#128229"],
        ["&#128230", "&#128230"],
        ["&#128231", "&#128231"],
        ["&#128232", "&#128232"],
        ["&#128233", "&#128233"],
        ["&#128234", "&#128234"],
        ["&#128235", "&#128235"],
        ["&#128236", "&#128236"],
        ["&#128237", "&#128237"],
        ["&#128238", "&#128238"],
        ["&#128239", "&#128239"],
        ["&#128240", "&#128240"],
        ["&#128241", "&#128241"],
        ["&#128242", "&#128242"],
        ["&#128243", "&#128243"],
        ["&#128244", "&#128244"],
        ["&#128245", "&#128245"],
        ["&#128246", "&#128246"],
        ["&#128247", "&#128247"],
        ["&#128248", "&#128248"],
        ["&#128249", "&#128249"],
        ["&#128250", "&#128250"],
        ["&#128251", "&#128251"],
        ["&#128252", "&#128252"],
        ["&#128253", "&#128253"],
        ["&#128255", "&#128255"],
        ["&#128256", "&#128256"],
        ["&#128257", "&#128257"],
        ["&#128258", "&#128258"],
        ["&#128259", "&#128259"],
        ["&#128260", "&#128260"],
        ["&#128261", "&#128261"],
        ["&#128262", "&#128262"],
        ["&#128263", "&#128263"],
        ["&#128264", "&#128264"],
        ["&#128265", "&#128265"],
        ["&#128266", "&#128266"],
        ["&#128267", "&#128267"],
        ["&#128268", "&#128268"],
        ["&#128269", "&#128269"],
        ["&#128270", "&#128270"],
        ["&#128271", "&#128271"],
        ["&#128272", "&#128272"],
        ["&#128273", "&#128273"],
        ["&#128274", "&#128274"],
        ["&#128275", "&#128275"],
        ["&#128276", "&#128276"],
        ["&#128277", "&#128277"],
        ["&#128278", "&#128278"],
        ["&#128279", "&#128279"],
        ["&#128280", "&#128280"],
        ["&#128281", "&#128281"],
        ["&#128282", "&#128282"],
        ["&#128283", "&#128283"],
        ["&#128284", "&#128284"],
        ["&#128285", "&#128285"],
        ["&#128286", "&#128286"],
        ["&#128287", "&#128287"],
        ["&#128288", "&#128288"],
        ["&#128289", "&#128289"],
        ["&#128290", "&#128290"],
        ["&#128291", "&#128291"],
        ["&#128292", "&#128292"],
        ["&#128293", "&#128293"],
        ["&#128294", "&#128294"],
        ["&#128295", "&#128295"],
        ["&#128296", "&#128296"],
        ["&#128297", "&#128297"],
        ["&#128298", "&#128298"],
        ["&#128299", "&#128299"],
        ["&#128300", "&#128300"],
        ["&#128301", "&#128301"],
        ["&#128302", "&#128302"],
        ["&#128303", "&#128303"],
        ["&#128304", "&#128304"],
        ["&#128305", "&#128305"],
        ["&#128306", "&#128306"],
        ["&#128307", "&#128307"],
        ["&#128308", "&#128308"],
        ["&#128309", "&#128309"],
        ["&#128310", "&#128310"],
        ["&#128311", "&#128311"],
        ["&#128312", "&#128312"],
        ["&#128313", "&#128313"],
        ["&#128314", "&#128314"],
        ["&#128315", "&#128315"],
        ["&#128316", "&#128316"],
        ["&#128317", "&#128317"],
        ["&#128329", "&#128329"],
        ["&#128330", "&#128330"],
        ["&#128331", "&#128331"],
        ["&#128332", "&#128332"],
        ["&#128333", "&#128333"],
        ["&#128334", "&#128334"],
        ["&#128336", "&#128336"],
        ["&#128337", "&#128337"],
        ["&#128338", "&#128338"],
        ["&#128339", "&#128339"],
        ["&#128340", "&#128340"],
        ["&#128341", "&#128341"],
        ["&#128342", "&#128342"],
        ["&#128343", "&#128343"],
        ["&#128344", "&#128344"],
        ["&#128345", "&#128345"],
        ["&#128346", "&#128346"],
        ["&#128347", "&#128347"],
        ["&#128348", "&#128348"],
        ["&#128349", "&#128349"],
        ["&#128350", "&#128350"],
        ["&#128351", "&#128351"],
        ["&#128352", "&#128352"],
        ["&#128353", "&#128353"],
        ["&#128354", "&#128354"],
        ["&#128355", "&#128355"],
        ["&#128356", "&#128356"],
        ["&#128357", "&#128357"],
        ["&#128358", "&#128358"],
        ["&#128359", "&#128359"],
        ["&#128367", "&#128367"],
        ["&#128368", "&#128368"],
        ["&#128371", "&#128371"],
        ["&#128372", "&#128372"],
        ["&#128373", "&#128373"],
        ["&#128374", "&#128374"],
        ["&#128375", "&#128375"],
        ["&#128376", "&#128376"],
        ["&#128377", "&#128377"],
        ["&#128378", "&#128378"],
        ["&#128391", "&#128391"],
        ["&#128394", "&#128394"],
        ["&#128395", "&#128395"],
        ["&#128396", "&#128396"],
        ["&#128397", "&#128397"],
        ["&#128400", "&#128400"],
        ["&#128405", "&#128405"],
        ["&#128406", "&#128406"],
        ["&#128420", "&#128420"],
        ["&#128421", "&#128421"],
        ["&#128424", "&#128424"],
        ["&#128433", "&#128433"],
        ["&#128434", "&#128434"],
        ["&#128444", "&#128444"],
        ["&#128450", "&#128450"],
        ["&#128451", "&#128451"],
        ["&#128452", "&#128452"],
        ["&#128465", "&#128465"],
        ["&#128466", "&#128466"],
        ["&#128467", "&#128467"],
        ["&#128476", "&#128476"],
        ["&#128477", "&#128477"],
        ["&#128478", "&#128478"],
        ["&#128481", "&#128481"],
        ["&#128483", "&#128483"],
        ["&#128488", "&#128488"],
        ["&#128495", "&#128495"],
        ["&#128499", "&#128499"],
        ["&#128506", "&#128506"],
        ["&#128507", "&#128507"],
        ["&#128508", "&#128508"],
        ["&#128509", "&#128509"],
        ["&#128510", "&#128510"],
        ["&#128511", "&#128511"],
        ["&#128512", "&#128512"],
        ["&#128513", "&#128513"],
        ["&#128514", "&#128514"],
        ["&#128515", "&#128515"],
        ["&#128516", "&#128516"],
        ["&#128517", "&#128517"],
        ["&#128518", "&#128518"],
        ["&#128519", "&#128519"],
        ["&#128520", "&#128520"],
        ["&#128521", "&#128521"],
        ["&#128522", "&#128522"],
        ["&#128523", "&#128523"],
        ["&#128524", "&#128524"],
        ["&#128525", "&#128525"],
        ["&#128526", "&#128526"],
        ["&#128527", "&#128527"],
        ["&#128528", "&#128528"],
        ["&#128529", "&#128529"],
        ["&#128530", "&#128530"],
        ["&#128531", "&#128531"],
        ["&#128532", "&#128532"],
        ["&#128533", "&#128533"],
        ["&#128534", "&#128534"],
        ["&#128535", "&#128535"],
        ["&#128536", "&#128536"],
        ["&#128537", "&#128537"],
        ["&#128538", "&#128538"],
        ["&#128539", "&#128539"],
        ["&#128540", "&#128540"],
        ["&#128541", "&#128541"],
        ["&#128542", "&#128542"],
        ["&#128543", "&#128543"],
        ["&#128544", "&#128544"],
        ["&#128545", "&#128545"],
        ["&#128546", "&#128546"],
        ["&#128547", "&#128547"],
        ["&#128548", "&#128548"],
        ["&#128549", "&#128549"],
        ["&#128550", "&#128550"],
        ["&#128551", "&#128551"],
        ["&#128552", "&#128552"],
        ["&#128553", "&#128553"],
        ["&#128554", "&#128554"],
        ["&#128555", "&#128555"],
        ["&#128556", "&#128556"],
        ["&#128557", "&#128557"],
        ["&#128558", "&#128558"],
        ["&#128559", "&#128559"],
        ["&#128560", "&#128560"],
        ["&#128561", "&#128561"],
        ["&#128562", "&#128562"],
        ["&#128563", "&#128563"],
        ["&#128564", "&#128564"],
        ["&#128565", "&#128565"],
        ["&#128566", "&#128566"],
        ["&#128567", "&#128567"],
        ["&#128568", "&#128568"],
        ["&#128569", "&#128569"],
        ["&#128570", "&#128570"],
        ["&#128571", "&#128571"],
        ["&#128572", "&#128572"],
        ["&#128573", "&#128573"],
        ["&#128574", "&#128574"],
        ["&#128575", "&#128575"],
        ["&#128576", "&#128576"],
        ["&#128577", "&#128577"],
        ["&#128578", "&#128578"],
        ["&#128579", "&#128579"],
        ["&#128580", "&#128580"],
        ["&#128581", "&#128581"],
        ["&#128582", "&#128582"],
        ["&#128583", "&#128583"],
        ["&#128584", "&#128584"],
        ["&#128585", "&#128585"],
        ["&#128586", "&#128586"],
        ["&#128587", "&#128587"],
        ["&#128588", "&#128588"],
        ["&#128589", "&#128589"],
        ["&#128590", "&#128590"],
        ["&#128591", "&#128591"],
        ["&#128640", "&#128640"],
        ["&#128641", "&#128641"],
        ["&#128642", "&#128642"],
        ["&#128643", "&#128643"],
        ["&#128644", "&#128644"],
        ["&#128645", "&#128645"],
        ["&#128646", "&#128646"],
        ["&#128647", "&#128647"],
        ["&#128648", "&#128648"],
        ["&#128649", "&#128649"],
        ["&#128650", "&#128650"],
        ["&#128651", "&#128651"],
        ["&#128652", "&#128652"],
        ["&#128653", "&#128653"],
        ["&#128654", "&#128654"],
        ["&#128655", "&#128655"],
        ["&#128656", "&#128656"],
        ["&#128657", "&#128657"],
        ["&#128658", "&#128658"],
        ["&#128659", "&#128659"],
        ["&#128660", "&#128660"],
        ["&#128661", "&#128661"],
        ["&#128662", "&#128662"],
        ["&#128663", "&#128663"],
        ["&#128664", "&#128664"],
        ["&#128665", "&#128665"],
        ["&#128666", "&#128666"],
        ["&#128667", "&#128667"],
        ["&#128668", "&#128668"],
        ["&#128669", "&#128669"],
        ["&#128670", "&#128670"],
        ["&#128671", "&#128671"],
        ["&#128672", "&#128672"],
        ["&#128673", "&#128673"],
        ["&#128674", "&#128674"],
        ["&#128675", "&#128675"],
        ["&#128676", "&#128676"],
        ["&#128677", "&#128677"],
        ["&#128678", "&#128678"],
        ["&#128679", "&#128679"],
        ["&#128680", "&#128680"],
        ["&#128681", "&#128681"],
        ["&#128682", "&#128682"],
        ["&#128683", "&#128683"],
        ["&#128684", "&#128684"],
        ["&#128685", "&#128685"],
        ["&#128686", "&#128686"],
        ["&#128687", "&#128687"],
        ["&#128688", "&#128688"],
        ["&#128689", "&#128689"],
        ["&#128690", "&#128690"],
        ["&#128691", "&#128691"],
        ["&#128692", "&#128692"],
        ["&#128693", "&#128693"],
        ["&#128694", "&#128694"],
        ["&#128695", "&#128695"],
        ["&#128696", "&#128696"],
        ["&#128697", "&#128697"],
        ["&#128698", "&#128698"],
        ["&#128699", "&#128699"],
        ["&#128700", "&#128700"],
        ["&#128701", "&#128701"],
        ["&#128702", "&#128702"],
        ["&#128703", "&#128703"],
        ["&#128704", "&#128704"],
        ["&#128705", "&#128705"],
        ["&#128706", "&#128706"],
        ["&#128707", "&#128707"],
        ["&#128708", "&#128708"],
        ["&#128709", "&#128709"],
        ["&#128715", "&#128715"],
        ["&#128716", "&#128716"],
        ["&#128717", "&#128717"],
        ["&#128718", "&#128718"],
        ["&#128719", "&#128719"],
        ["&#128720", "&#128720"],
        ["&#128721", "&#128721"],
        ["&#128722", "&#128722"],
        ["&#128736", "&#128736"],
        ["&#128737", "&#128737"],
        ["&#128738", "&#128738"],
        ["&#128739", "&#128739"],
        ["&#128740", "&#128740"],
        ["&#128741", "&#128741"],
        ["&#128745", "&#128745"],
        ["&#128747", "&#128747"],
        ["&#128748", "&#128748"],
        ["&#128752", "&#128752"],
        ["&#128755", "&#128755"],
        ["&#128756", "&#128756"],
        ["&#128757", "&#128757"],
        ["&#128758", "&#128758"],
        ["&#128759", "&#128759"],
        ["&#128760", "&#128760"],
        ["&#128761", "&#128761"],
        ["&#128762", "&#128762"],
        ["&#129296", "&#129296"],
        ["&#129297", "&#129297"],
        ["&#129298", "&#129298"],
        ["&#129299", "&#129299"],
        ["&#129300", "&#129300"],
        ["&#129301", "&#129301"],
        ["&#129302", "&#129302"],
        ["&#129303", "&#129303"],
        ["&#129304", "&#129304"],
        ["&#129305", "&#129305"],
        ["&#129306", "&#129306"],
        ["&#129307", "&#129307"],
        ["&#129308", "&#129308"],
        ["&#129309", "&#129309"],
        ["&#129310", "&#129310"],
        ["&#129311", "&#129311"],
        ["&#129312", "&#129312"],
        ["&#129313", "&#129313"],
        ["&#129314", "&#129314"],
        ["&#129315", "&#129315"],
        ["&#129316", "&#129316"],
        ["&#129317", "&#129317"],
        ["&#129318", "&#129318"],
        ["&#129319", "&#129319"],
        ["&#129320", "&#129320"],
        ["&#129321", "&#129321"],
        ["&#129322", "&#129322"],
        ["&#129323", "&#129323"],
        ["&#129324", "&#129324"],
        ["&#129325", "&#129325"],
        ["&#129326", "&#129326"],
        ["&#129327", "&#129327"],
        ["&#129328", "&#129328"],
        ["&#129329", "&#129329"],
        ["&#129330", "&#129330"],
        ["&#129331", "&#129331"],
        ["&#129332", "&#129332"],
        ["&#129333", "&#129333"],
        ["&#129334", "&#129334"],
        ["&#129335", "&#129335"],
        ["&#129336", "&#129336"],
        ["&#129337", "&#129337"],
        ["&#129338", "&#129338"],
        ["&#129340", "&#129340"],
        ["&#129341", "&#129341"],
        ["&#129342", "&#129342"],
        ["&#129344", "&#129344"],
        ["&#129345", "&#129345"],
        ["&#129346", "&#129346"],
        ["&#129347", "&#129347"],
        ["&#129348", "&#129348"],
        ["&#129349", "&#129349"],
        ["&#129351", "&#129351"],
        ["&#129352", "&#129352"],
        ["&#129353", "&#129353"],
        ["&#129354", "&#129354"],
        ["&#129355", "&#129355"],
        ["&#129356", "&#129356"],
        ["&#129357", "&#129357"],
        ["&#129358", "&#129358"],
        ["&#129359", "&#129359"],
        ["&#129360", "&#129360"],
        ["&#129361", "&#129361"],
        ["&#129362", "&#129362"],
        ["&#129363", "&#129363"],
        ["&#129364", "&#129364"],
        ["&#129365", "&#129365"],
        ["&#129366", "&#129366"],
        ["&#129367", "&#129367"],
        ["&#129368", "&#129368"],
        ["&#129369", "&#129369"],
        ["&#129370", "&#129370"],
        ["&#129371", "&#129371"],
        ["&#129372", "&#129372"],
        ["&#129373", "&#129373"],
        ["&#129374", "&#129374"],
        ["&#129375", "&#129375"],
        ["&#129376", "&#129376"],
        ["&#129377", "&#129377"],
        ["&#129378", "&#129378"],
        ["&#129379", "&#129379"],
        ["&#129380", "&#129380"],
        ["&#129381", "&#129381"],
        ["&#129382", "&#129382"],
        ["&#129383", "&#129383"],
        ["&#129384", "&#129384"],
        ["&#129385", "&#129385"],
        ["&#129386", "&#129386"],
        ["&#129387", "&#129387"],
        ["&#129408", "&#129408"],
        ["&#129409", "&#129409"],
        ["&#129410", "&#129410"],
        ["&#129411", "&#129411"],
        ["&#129412", "&#129412"],
        ["&#129413", "&#129413"],
        ["&#129414", "&#129414"],
        ["&#129415", "&#129415"],
        ["&#129416", "&#129416"],
        ["&#129417", "&#129417"],
        ["&#129418", "&#129418"],
        ["&#129419", "&#129419"],
        ["&#129420", "&#129420"],
        ["&#129421", "&#129421"],
        ["&#129422", "&#129422"],
        ["&#129423", "&#129423"],
        ["&#129424", "&#129424"],
        ["&#129425", "&#129425"],
        ["&#129426", "&#129426"],
        ["&#129427", "&#129427"],
        ["&#129428", "&#129428"],
        ["&#129429", "&#129429"],
        ["&#129430", "&#129430"],
        ["&#129431", "&#129431"],
        ["&#129472", "&#129472"],
        ["&#129488", "&#129488"],
        ["&#129489", "&#129489"],
        ["&#129490", "&#129490"],
        ["&#129491", "&#129491"],
        ["&#129492", "&#129492"],
        ["&#129493", "&#129493"],
        ["&#129494", "&#129494"],
        ["&#129495", "&#129495"],
        ["&#129496", "&#129496"],
        ["&#129497", "&#129497"],
        ["&#129498", "&#129498"],
        ["&#129499", "&#129499"],
        ["&#129500", "&#129500"],
        ["&#129501", "&#129501"],
        ["&#129502", "&#129502"],
        ["&#129503", "&#129503"],
        ["&#129504", "&#129504"],
        ["&#129505", "&#129505"],
        ["&#129506", "&#129506"],
        ["&#129507", "&#129507"],
        ["&#129508", "&#129508"],
        ["&#129509", "&#129509"],
        ["&#129510", "&#129510"],
        ['&#9757;&#127999;', '&#9757,&#127999;'],
        ['&#9977;&#127999;', '&#9977,&#127999;'],
        ['&#9994;&#127999;', '&#9994,&#127999;'],
        ['&#9995;&#127999;', '&#9995,&#127999;'],
        ['&#9996;&#127999;', '&#9996,&#127999;'],
        ['&#9997;&#127999;', '&#9997,&#127999;'],
        ['&#127877;&#127999;', '&#127877,&#127999;'],
        ['&#127938;&#127999;', '&#127938,&#127999;'],
        ['&#127939;&#127999;', '&#127939,&#127999;'],
        ['&#127940;&#127999;', '&#127940,&#127999;'],
        ['&#127943;&#127999;', '&#127943,&#127999;'],
        ['&#127946;&#127999;', '&#127946,&#127999;'],
        ['&#127947;&#127999;', '&#127947,&#127999;'],
        ['&#127948;&#127999;', '&#127948,&#127999;'],
        ['&#128066;&#127999;', '&#128066,&#127999;'],
        ['&#128067;&#127999;', '&#128067,&#127999;'],
        ['&#128070;&#127999;', '&#128070,&#127999;'],
        ['&#128071;&#127999;', '&#128071,&#127999;'],
        ['&#128072;&#127999;', '&#128072,&#127999;'],
        ['&#128073;&#127999;', '&#128073,&#127999;'],
        ['&#128074;&#127999;', '&#128074,&#127999;'],
        ['&#128075;&#127999;', '&#128075,&#127999;'],
        ['&#128076;&#127999;', '&#128076,&#127999;'],
        ['&#128077;&#127999;', '&#128077,&#127999;'],
        ['&#128078;&#127999;', '&#128078,&#127999;'],
        ['&#128079;&#127999;', '&#128079,&#127999;'],
        ['&#128080;&#127999;', '&#128080,&#127999;'],
        ['&#128102;&#127999;', '&#128102,&#127999;'],
        ['&#128103;&#127999;', '&#128103,&#127999;'],
        ['&#128104;&#127999;', '&#128104,&#127999;'],
        ['&#128105;&#127999;', '&#128105,&#127999;'],
        ['&#128110;&#127999;', '&#128110,&#127999;'],
        ['&#128112;&#127999;', '&#128112,&#127999;'],
        ['&#128113;&#127999;', '&#128113,&#127999;'],
        ['&#128114;&#127999;', '&#128114,&#127999;'],
        ['&#128115;&#127999;', '&#128115,&#127999;'],
        ['&#128116;&#127999;', '&#128116,&#127999;'],
        ['&#128117;&#127999;', '&#128117,&#127999;'],
        ['&#128118;&#127999;', '&#128118,&#127999;'],
        ['&#128119;&#127999;', '&#128119,&#127999;'],
        ['&#128120;&#127999;', '&#128120,&#127999;'],
        ['&#128124;&#127999;', '&#128124,&#127999;'],
        ['&#128129;&#127999;', '&#128129,&#127999;'],
        ['&#128130;&#127999;', '&#128130,&#127999;'],
        ['&#128131;&#127999;', '&#128131,&#127999;'],
        ['&#128133;&#127999;', '&#128133,&#127999;'],
        ['&#128134;&#127999;', '&#128134,&#127999;'],
        ['&#128135;&#127999;', '&#128135,&#127999;'],
        ['&#128170;&#127999;', '&#128170,&#127999;'],
        ['&#128372;&#127999;', '&#128372,&#127999;'],
        ['&#128373;&#127999;', '&#128373,&#127999;'],
        ['&#128378;&#127999;', '&#128378,&#127999;'],
        ['&#128400;&#127999;', '&#128400,&#127999;'],
        ['&#128405;&#127999;', '&#128405,&#127999;'],
        ['&#128406;&#127999;', '&#128406,&#127999;'],
        ['&#128581;&#127999;', '&#128581,&#127999;'],
        ['&#128582;&#127999;', '&#128582,&#127999;'],
        ['&#128583;&#127999;', '&#128583,&#127999;'],
        ['&#128587;&#127999;', '&#128587,&#127999;'],
        ['&#128588;&#127999;', '&#128588,&#127999;'],
        ['&#128589;&#127999;', '&#128589,&#127999;'],
        ['&#128590;&#127999;', '&#128590,&#127999;'],
        ['&#128591;&#127999;', '&#128591,&#127999;'],
        ['&#128675;&#127999;', '&#128675,&#127999;'],
        ['&#128692;&#127999;', '&#128692,&#127999;'],
        ['&#128693;&#127999;', '&#128693,&#127999;'],
        ['&#128694;&#127999;', '&#128694,&#127999;'],
        ['&#128704;&#127999;', '&#128704,&#127999;'],
        ['&#128716;&#127999;', '&#128716,&#127999;'],
        ['&#129304;&#127999;', '&#129304,&#127999;'],
        ['&#129305;&#127999;', '&#129305,&#127999;'],
        ['&#129306;&#127999;', '&#129306,&#127999;'],
        ['&#129307;&#127999;', '&#129307,&#127999;'],
        ['&#129308;&#127999;', '&#129308,&#127999;'],
        ['&#129309;&#127999;', '&#129309,&#127999;'],
        ['&#129310;&#127999;', '&#129310,&#127999;'],
        ['&#129311;&#127999;', '&#129311,&#127999;'],
        ['&#129318;&#127999;', '&#129318,&#127999;'],
        ['&#129328;&#127999;', '&#129328,&#127999;'],
        ['&#129329;&#127999;', '&#129329,&#127999;'],
        ['&#129330;&#127999;', '&#129330,&#127999;'],
        ['&#129331;&#127999;', '&#129331,&#127999;'],
        ['&#129332;&#127999;', '&#129332,&#127999;'],
        ['&#129333;&#127999;', '&#129333,&#127999;'],
        ['&#129334;&#127999;', '&#129334,&#127999;'],
        ['&#129335;&#127999;', '&#129335,&#127999;'],
        ['&#129336;&#127999;', '&#129336,&#127999;'],
        ['&#129337;&#127999;', '&#129337,&#127999;'],
        ['&#129341;&#127999;', '&#129341,&#127999;'],
        ['&#129342;&#127999;', '&#129342,&#127999;'],
        ['&#129489;&#127999;', '&#129489,&#127999;'],
        ['&#129490;&#127999;', '&#129490,&#127999;'],
        ['&#129491;&#127999;', '&#129491,&#127999;'],
        ['&#129492;&#127999;', '&#129492,&#127999;'],
        ['&#129493;&#127999;', '&#129493,&#127999;'],
        ['&#129494;&#127999;', '&#129494,&#127999;'],
        ['&#129495;&#127999;', '&#129495,&#127999;'],
        ['&#129496;&#127999;', '&#129496,&#127999;'],
        ['&#129497;&#127999;', '&#129497,&#127999;'],
        ['&#129498;&#127999;', '&#129498,&#127999;'],
        ['&#129500;&#127999;', '&#129500,&#127999;'],
        ['&#129501;&#127999;', '&#129501,&#127999;'],
        ['&#9757;&#127998;', '&#9757,&#127998;'],
        ['&#9977;&#127998;', '&#9977,&#127998;'],
        ['&#9994;&#127998;', '&#9994,&#127998;'],
        ['&#9995;&#127998;', '&#9995,&#127998;'],
        ['&#9996;&#127998;', '&#9996,&#127998;'],
        ['&#9997;&#127998;', '&#9997,&#127998;'],
        ['&#127877;&#127998;', '&#127877,&#127998;'],
        ['&#127938;&#127998;', '&#127938,&#127998;'],
        ['&#127939;&#127998;', '&#127939,&#127998;'],
        ['&#127940;&#127998;', '&#127940,&#127998;'],
        ['&#127943;&#127998;', '&#127943,&#127998;'],
        ['&#127946;&#127998;', '&#127946,&#127998;'],
        ['&#127947;&#127998;', '&#127947,&#127998;'],
        ['&#127948;&#127998;', '&#127948,&#127998;'],
        ['&#128066;&#127998;', '&#128066,&#127998;'],
        ['&#128067;&#127998;', '&#128067,&#127998;'],
        ['&#128070;&#127998;', '&#128070,&#127998;'],
        ['&#128071;&#127998;', '&#128071,&#127998;'],
        ['&#128072;&#127998;', '&#128072,&#127998;'],
        ['&#128073;&#127998;', '&#128073,&#127998;'],
        ['&#128074;&#127998;', '&#128074,&#127998;'],
        ['&#128075;&#127998;', '&#128075,&#127998;'],
        ['&#128076;&#127998;', '&#128076,&#127998;'],
        ['&#128077;&#127998;', '&#128077,&#127998;'],
        ['&#128078;&#127998;', '&#128078,&#127998;'],
        ['&#128079;&#127998;', '&#128079,&#127998;'],
        ['&#128080;&#127998;', '&#128080,&#127998;'],
        ['&#128102;&#127998;', '&#128102,&#127998;'],
        ['&#128103;&#127998;', '&#128103,&#127998;'],
        ['&#128104;&#127998;', '&#128104,&#127998;'],
        ['&#128105;&#127998;', '&#128105,&#127998;'],
        ['&#128110;&#127998;', '&#128110,&#127998;'],
        ['&#128112;&#127998;', '&#128112,&#127998;'],
        ['&#128113;&#127998;', '&#128113,&#127998;'],
        ['&#128114;&#127998;', '&#128114,&#127998;'],
        ['&#128115;&#127998;', '&#128115,&#127998;'],
        ['&#128116;&#127998;', '&#128116,&#127998;'],
        ['&#128117;&#127998;', '&#128117,&#127998;'],
        ['&#128118;&#127998;', '&#128118,&#127998;'],
        ['&#128119;&#127998;', '&#128119,&#127998;'],
        ['&#128120;&#127998;', '&#128120,&#127998;'],
        ['&#128124;&#127998;', '&#128124,&#127998;'],
        ['&#128129;&#127998;', '&#128129,&#127998;'],
        ['&#128130;&#127998;', '&#128130,&#127998;'],
        ['&#128131;&#127998;', '&#128131,&#127998;'],
        ['&#128133;&#127998;', '&#128133,&#127998;'],
        ['&#128134;&#127998;', '&#128134,&#127998;'],
        ['&#128135;&#127998;', '&#128135,&#127998;'],
        ['&#128170;&#127998;', '&#128170,&#127998;'],
        ['&#128372;&#127998;', '&#128372,&#127998;'],
        ['&#128373;&#127998;', '&#128373,&#127998;'],
        ['&#128378;&#127998;', '&#128378,&#127998;'],
        ['&#128400;&#127998;', '&#128400,&#127998;'],
        ['&#128405;&#127998;', '&#128405,&#127998;'],
        ['&#128406;&#127998;', '&#128406,&#127998;'],
        ['&#128581;&#127998;', '&#128581,&#127998;'],
        ['&#128582;&#127998;', '&#128582,&#127998;'],
        ['&#128583;&#127998;', '&#128583,&#127998;'],
        ['&#128587;&#127998;', '&#128587,&#127998;'],
        ['&#128588;&#127998;', '&#128588,&#127998;'],
        ['&#128589;&#127998;', '&#128589,&#127998;'],
        ['&#128590;&#127998;', '&#128590,&#127998;'],
        ['&#128591;&#127998;', '&#128591,&#127998;'],
        ['&#128675;&#127998;', '&#128675,&#127998;'],
        ['&#128692;&#127998;', '&#128692,&#127998;'],
        ['&#128693;&#127998;', '&#128693,&#127998;'],
        ['&#128694;&#127998;', '&#128694,&#127998;'],
        ['&#128704;&#127998;', '&#128704,&#127998;'],
        ['&#128716;&#127998;', '&#128716,&#127998;'],
        ['&#129304;&#127998;', '&#129304,&#127998;'],
        ['&#129305;&#127998;', '&#129305,&#127998;'],
        ['&#129306;&#127998;', '&#129306,&#127998;'],
        ['&#129307;&#127998;', '&#129307,&#127998;'],
        ['&#129308;&#127998;', '&#129308,&#127998;'],
        ['&#129309;&#127998;', '&#129309,&#127998;'],
        ['&#129310;&#127998;', '&#129310,&#127998;'],
        ['&#129311;&#127998;', '&#129311,&#127998;'],
        ['&#129318;&#127998;', '&#129318,&#127998;'],
        ['&#129328;&#127998;', '&#129328,&#127998;'],
        ['&#129329;&#127998;', '&#129329,&#127998;'],
        ['&#129330;&#127998;', '&#129330,&#127998;'],
        ['&#129331;&#127998;', '&#129331,&#127998;'],
        ['&#129332;&#127998;', '&#129332,&#127998;'],
        ['&#129333;&#127998;', '&#129333,&#127998;'],
        ['&#129334;&#127998;', '&#129334,&#127998;'],
        ['&#129335;&#127998;', '&#129335,&#127998;'],
        ['&#129336;&#127998;', '&#129336,&#127998;'],
        ['&#129337;&#127998;', '&#129337,&#127998;'],
        ['&#129341;&#127998;', '&#129341,&#127998;'],
        ['&#129342;&#127998;', '&#129342,&#127998;'],
        ['&#129489;&#127998;', '&#129489,&#127998;'],
        ['&#129490;&#127998;', '&#129490,&#127998;'],
        ['&#129491;&#127998;', '&#129491,&#127998;'],
        ['&#129492;&#127998;', '&#129492,&#127998;'],
        ['&#129493;&#127998;', '&#129493,&#127998;'],
        ['&#129494;&#127998;', '&#129494,&#127998;'],
        ['&#129495;&#127998;', '&#129495,&#127998;'],
        ['&#129496;&#127998;', '&#129496,&#127998;'],
        ['&#129497;&#127998;', '&#129497,&#127998;'],
        ['&#129498;&#127998;', '&#129498,&#127998;'],
        ['&#129500;&#127998;', '&#129500,&#127998;'],
        ['&#129501;&#127998;', '&#129501,&#127998;'],
        ['&#9757;&#127997;', '&#9757,&#127997;'],
        ['&#9977;&#127997;', '&#9977,&#127997;'],
        ['&#9994;&#127997;', '&#9994,&#127997;'],
        ['&#9995;&#127997;', '&#9995,&#127997;'],
        ['&#9996;&#127997;', '&#9996,&#127997;'],
        ['&#9997;&#127997;', '&#9997,&#127997;'],
        ['&#127877;&#127997;', '&#127877,&#127997;'],
        ['&#127938;&#127997;', '&#127938,&#127997;'],
        ['&#127939;&#127997;', '&#127939,&#127997;'],
        ['&#127940;&#127997;', '&#127940,&#127997;'],
        ['&#127943;&#127997;', '&#127943,&#127997;'],
        ['&#127946;&#127997;', '&#127946,&#127997;'],
        ['&#127947;&#127997;', '&#127947,&#127997;'],
        ['&#127948;&#127997;', '&#127948,&#127997;'],
        ['&#128066;&#127997;', '&#128066,&#127997;'],
        ['&#128067;&#127997;', '&#128067,&#127997;'],
        ['&#128070;&#127997;', '&#128070,&#127997;'],
        ['&#128071;&#127997;', '&#128071,&#127997;'],
        ['&#128072;&#127997;', '&#128072,&#127997;'],
        ['&#128073;&#127997;', '&#128073,&#127997;'],
        ['&#128074;&#127997;', '&#128074,&#127997;'],
        ['&#128075;&#127997;', '&#128075,&#127997;'],
        ['&#128076;&#127997;', '&#128076,&#127997;'],
        ['&#128077;&#127997;', '&#128077,&#127997;'],
        ['&#128078;&#127997;', '&#128078,&#127997;'],
        ['&#128079;&#127997;', '&#128079,&#127997;'],
        ['&#128080;&#127997;', '&#128080,&#127997;'],
        ['&#128102;&#127997;', '&#128102,&#127997;'],
        ['&#128103;&#127997;', '&#128103,&#127997;'],
        ['&#128104;&#127997;', '&#128104,&#127997;'],
        ['&#128105;&#127997;', '&#128105,&#127997;'],
        ['&#128110;&#127997;', '&#128110,&#127997;'],
        ['&#128112;&#127997;', '&#128112,&#127997;'],
        ['&#128113;&#127997;', '&#128113,&#127997;'],
        ['&#128114;&#127997;', '&#128114,&#127997;'],
        ['&#128115;&#127997;', '&#128115,&#127997;'],
        ['&#128116;&#127997;', '&#128116,&#127997;'],
        ['&#128117;&#127997;', '&#128117,&#127997;'],
        ['&#128118;&#127997;', '&#128118,&#127997;'],
        ['&#128119;&#127997;', '&#128119,&#127997;'],
        ['&#128120;&#127997;', '&#128120,&#127997;'],
        ['&#128124;&#127997;', '&#128124,&#127997;'],
        ['&#128129;&#127997;', '&#128129,&#127997;'],
        ['&#128130;&#127997;', '&#128130,&#127997;'],
        ['&#128131;&#127997;', '&#128131,&#127997;'],
        ['&#128133;&#127997;', '&#128133,&#127997;'],
        ['&#128134;&#127997;', '&#128134,&#127997;'],
        ['&#128135;&#127997;', '&#128135,&#127997;'],
        ['&#128170;&#127997;', '&#128170,&#127997;'],
        ['&#128372;&#127997;', '&#128372,&#127997;'],
        ['&#128373;&#127997;', '&#128373,&#127997;'],
        ['&#128378;&#127997;', '&#128378,&#127997;'],
        ['&#128400;&#127997;', '&#128400,&#127997;'],
        ['&#128405;&#127997;', '&#128405,&#127997;'],
        ['&#128406;&#127997;', '&#128406,&#127997;'],
        ['&#128581;&#127997;', '&#128581,&#127997;'],
        ['&#128582;&#127997;', '&#128582,&#127997;'],
        ['&#128583;&#127997;', '&#128583,&#127997;'],
        ['&#128587;&#127997;', '&#128587,&#127997;'],
        ['&#128588;&#127997;', '&#128588,&#127997;'],
        ['&#128589;&#127997;', '&#128589,&#127997;'],
        ['&#128590;&#127997;', '&#128590,&#127997;'],
        ['&#128591;&#127997;', '&#128591,&#127997;'],
        ['&#128675;&#127997;', '&#128675,&#127997;'],
        ['&#128692;&#127997;', '&#128692,&#127997;'],
        ['&#128693;&#127997;', '&#128693,&#127997;'],
        ['&#128694;&#127997;', '&#128694,&#127997;'],
        ['&#128704;&#127997;', '&#128704,&#127997;'],
        ['&#128716;&#127997;', '&#128716,&#127997;'],
        ['&#129304;&#127997;', '&#129304,&#127997;'],
        ['&#129305;&#127997;', '&#129305,&#127997;'],
        ['&#129306;&#127997;', '&#129306,&#127997;'],
        ['&#129307;&#127997;', '&#129307,&#127997;'],
        ['&#129308;&#127997;', '&#129308,&#127997;'],
        ['&#129309;&#127997;', '&#129309,&#127997;'],
        ['&#129310;&#127997;', '&#129310,&#127997;'],
        ['&#129311;&#127997;', '&#129311,&#127997;'],
        ['&#129318;&#127997;', '&#129318,&#127997;'],
        ['&#129328;&#127997;', '&#129328,&#127997;'],
        ['&#129329;&#127997;', '&#129329,&#127997;'],
        ['&#129330;&#127997;', '&#129330,&#127997;'],
        ['&#129331;&#127997;', '&#129331,&#127997;'],
        ['&#129332;&#127997;', '&#129332,&#127997;'],
        ['&#129333;&#127997;', '&#129333,&#127997;'],
        ['&#129334;&#127997;', '&#129334,&#127997;'],
        ['&#129335;&#127997;', '&#129335,&#127997;'],
        ['&#129336;&#127997;', '&#129336,&#127997;'],
        ['&#129337;&#127997;', '&#129337,&#127997;'],
        ['&#129341;&#127997;', '&#129341,&#127997;'],
        ['&#129342;&#127997;', '&#129342,&#127997;'],
        ['&#129489;&#127997;', '&#129489,&#127997;'],
        ['&#129490;&#127997;', '&#129490,&#127997;'],
        ['&#129491;&#127997;', '&#129491,&#127997;'],
        ['&#129492;&#127997;', '&#129492,&#127997;'],
        ['&#129493;&#127997;', '&#129493,&#127997;'],
        ['&#129494;&#127997;', '&#129494,&#127997;'],
        ['&#129495;&#127997;', '&#129495,&#127997;'],
        ['&#129496;&#127997;', '&#129496,&#127997;'],
        ['&#129497;&#127997;', '&#129497,&#127997;'],
        ['&#129498;&#127997;', '&#129498,&#127997;'],
        ['&#129500;&#127997;', '&#129500,&#127997;'],
        ['&#129501;&#127997;', '&#129501,&#127997;'],
        ['&#9757;&#127996;', '&#9757,&#127996;'],
        ['&#9977;&#127996;', '&#9977,&#127996;'],
        ['&#9994;&#127996;', '&#9994,&#127996;'],
        ['&#9995;&#127996;', '&#9995,&#127996;'],
        ['&#9996;&#127996;', '&#9996,&#127996;'],
        ['&#9997;&#127996;', '&#9997,&#127996;'],
        ['&#127877;&#127996;', '&#127877,&#127996;'],
        ['&#127938;&#127996;', '&#127938,&#127996;'],
        ['&#127939;&#127996;', '&#127939,&#127996;'],
        ['&#127940;&#127996;', '&#127940,&#127996;'],
        ['&#127943;&#127996;', '&#127943,&#127996;'],
        ['&#127946;&#127996;', '&#127946,&#127996;'],
        ['&#127947;&#127996;', '&#127947,&#127996;'],
        ['&#127948;&#127996;', '&#127948,&#127996;'],
        ['&#128066;&#127996;', '&#128066,&#127996;'],
        ['&#128067;&#127996;', '&#128067,&#127996;'],
        ['&#128070;&#127996;', '&#128070,&#127996;'],
        ['&#128071;&#127996;', '&#128071,&#127996;'],
        ['&#128072;&#127996;', '&#128072,&#127996;'],
        ['&#128073;&#127996;', '&#128073,&#127996;'],
        ['&#128074;&#127996;', '&#128074,&#127996;'],
        ['&#128075;&#127996;', '&#128075,&#127996;'],
        ['&#128076;&#127996;', '&#128076,&#127996;'],
        ['&#128077;&#127996;', '&#128077,&#127996;'],
        ['&#128078;&#127996;', '&#128078,&#127996;'],
        ['&#128079;&#127996;', '&#128079,&#127996;'],
        ['&#128080;&#127996;', '&#128080,&#127996;'],
        ['&#128102;&#127996;', '&#128102,&#127996;'],
        ['&#128103;&#127996;', '&#128103,&#127996;'],
        ['&#128104;&#127996;', '&#128104,&#127996;'],
        ['&#128105;&#127996;', '&#128105,&#127996;'],
        ['&#128110;&#127996;', '&#128110,&#127996;'],
        ['&#128112;&#127996;', '&#128112,&#127996;'],
        ['&#128113;&#127996;', '&#128113,&#127996;'],
        ['&#128114;&#127996;', '&#128114,&#127996;'],
        ['&#128115;&#127996;', '&#128115,&#127996;'],
        ['&#128116;&#127996;', '&#128116,&#127996;'],
        ['&#128117;&#127996;', '&#128117,&#127996;'],
        ['&#128118;&#127996;', '&#128118,&#127996;'],
        ['&#128119;&#127996;', '&#128119,&#127996;'],
        ['&#128120;&#127996;', '&#128120,&#127996;'],
        ['&#128124;&#127996;', '&#128124,&#127996;'],
        ['&#128129;&#127996;', '&#128129,&#127996;'],
        ['&#128130;&#127996;', '&#128130,&#127996;'],
        ['&#128131;&#127996;', '&#128131,&#127996;'],
        ['&#128133;&#127996;', '&#128133,&#127996;'],
        ['&#128134;&#127996;', '&#128134,&#127996;'],
        ['&#128135;&#127996;', '&#128135,&#127996;'],
        ['&#128170;&#127996;', '&#128170,&#127996;'],
        ['&#128372;&#127996;', '&#128372,&#127996;'],
        ['&#128373;&#127996;', '&#128373,&#127996;'],
        ['&#128378;&#127996;', '&#128378,&#127996;'],
        ['&#128400;&#127996;', '&#128400,&#127996;'],
        ['&#128405;&#127996;', '&#128405,&#127996;'],
        ['&#128406;&#127996;', '&#128406,&#127996;'],
        ['&#128581;&#127996;', '&#128581,&#127996;'],
        ['&#128582;&#127996;', '&#128582,&#127996;'],
        ['&#128583;&#127996;', '&#128583,&#127996;'],
        ['&#128587;&#127996;', '&#128587,&#127996;'],
        ['&#128588;&#127996;', '&#128588,&#127996;'],
        ['&#128589;&#127996;', '&#128589,&#127996;'],
        ['&#128590;&#127996;', '&#128590,&#127996;'],
        ['&#128591;&#127996;', '&#128591,&#127996;'],
        ['&#128675;&#127996;', '&#128675,&#127996;'],
        ['&#128692;&#127996;', '&#128692,&#127996;'],
        ['&#128693;&#127996;', '&#128693,&#127996;'],
        ['&#128694;&#127996;', '&#128694,&#127996;'],
        ['&#128704;&#127996;', '&#128704,&#127996;'],
        ['&#128716;&#127996;', '&#128716,&#127996;'],
        ['&#129304;&#127996;', '&#129304,&#127996;'],
        ['&#129305;&#127996;', '&#129305,&#127996;'],
        ['&#129306;&#127996;', '&#129306,&#127996;'],
        ['&#129307;&#127996;', '&#129307,&#127996;'],
        ['&#129308;&#127996;', '&#129308,&#127996;'],
        ['&#129309;&#127996;', '&#129309,&#127996;'],
        ['&#129310;&#127996;', '&#129310,&#127996;'],
        ['&#129311;&#127996;', '&#129311,&#127996;'],
        ['&#129318;&#127996;', '&#129318,&#127996;'],
        ['&#129328;&#127996;', '&#129328,&#127996;'],
        ['&#129329;&#127996;', '&#129329,&#127996;'],
        ['&#129330;&#127996;', '&#129330,&#127996;'],
        ['&#129331;&#127996;', '&#129331,&#127996;'],
        ['&#129332;&#127996;', '&#129332,&#127996;'],
        ['&#129333;&#127996;', '&#129333,&#127996;'],
        ['&#129334;&#127996;', '&#129334,&#127996;'],
        ['&#129335;&#127996;', '&#129335,&#127996;'],
        ['&#129336;&#127996;', '&#129336,&#127996;'],
        ['&#129337;&#127996;', '&#129337,&#127996;'],
        ['&#129341;&#127996;', '&#129341,&#127996;'],
        ['&#129342;&#127996;', '&#129342,&#127996;'],
        ['&#129489;&#127996;', '&#129489,&#127996;'],
        ['&#129490;&#127996;', '&#129490,&#127996;'],
        ['&#129491;&#127996;', '&#129491,&#127996;'],
        ['&#129492;&#127996;', '&#129492,&#127996;'],
        ['&#129493;&#127996;', '&#129493,&#127996;'],
        ['&#129494;&#127996;', '&#129494,&#127996;'],
        ['&#129495;&#127996;', '&#129495,&#127996;'],
        ['&#129496;&#127996;', '&#129496,&#127996;'],
        ['&#129497;&#127996;', '&#129497,&#127996;'],
        ['&#129498;&#127996;', '&#129498,&#127996;'],
        ['&#129500;&#127996;', '&#129500,&#127996;'],
        ['&#129501;&#127996;', '&#129501,&#127996;'],
        ['&#9757;&#127995;', '&#9757,&#127995;'],
        ['&#9977;&#127995;', '&#9977,&#127995;'],
        ['&#9994;&#127995;', '&#9994,&#127995;'],
        ['&#9995;&#127995;', '&#9995,&#127995;'],
        ['&#9996;&#127995;', '&#9996,&#127995;'],
        ['&#9997;&#127995;', '&#9997,&#127995;'],
        ['&#127877;&#127995;', '&#127877,&#127995;'],
        ['&#127938;&#127995;', '&#127938,&#127995;'],
        ['&#127939;&#127995;', '&#127939,&#127995;'],
        ['&#127940;&#127995;', '&#127940,&#127995;'],
        ['&#127943;&#127995;', '&#127943,&#127995;'],
        ['&#127946;&#127995;', '&#127946,&#127995;'],
        ['&#127947;&#127995;', '&#127947,&#127995;'],
        ['&#127948;&#127995;', '&#127948,&#127995;'],
        ['&#128066;&#127995;', '&#128066,&#127995;'],
        ['&#128067;&#127995;', '&#128067,&#127995;'],
        ['&#128070;&#127995;', '&#128070,&#127995;'],
        ['&#128071;&#127995;', '&#128071,&#127995;'],
        ['&#128072;&#127995;', '&#128072,&#127995;'],
        ['&#128073;&#127995;', '&#128073,&#127995;'],
        ['&#128074;&#127995;', '&#128074,&#127995;'],
        ['&#128075;&#127995;', '&#128075,&#127995;'],
        ['&#128076;&#127995;', '&#128076,&#127995;'],
        ['&#128077;&#127995;', '&#128077,&#127995;'],
        ['&#128078;&#127995;', '&#128078,&#127995;'],
        ['&#128079;&#127995;', '&#128079,&#127995;'],
        ['&#128080;&#127995;', '&#128080,&#127995;'],
        ['&#128102;&#127995;', '&#128102,&#127995;'],
        ['&#128103;&#127995;', '&#128103,&#127995;'],
        ['&#128104;&#127995;', '&#128104,&#127995;'],
        ['&#128105;&#127995;', '&#128105,&#127995;'],
        ['&#128110;&#127995;', '&#128110,&#127995;'],
        ['&#128112;&#127995;', '&#128112,&#127995;'],
        ['&#128113;&#127995;', '&#128113,&#127995;'],
        ['&#128114;&#127995;', '&#128114,&#127995;'],
        ['&#128115;&#127995;', '&#128115,&#127995;'],
        ['&#128116;&#127995;', '&#128116,&#127995;'],
        ['&#128117;&#127995;', '&#128117,&#127995;'],
        ['&#128118;&#127995;', '&#128118,&#127995;'],
        ['&#128119;&#127995;', '&#128119,&#127995;'],
        ['&#128120;&#127995;', '&#128120,&#127995;'],
        ['&#128124;&#127995;', '&#128124,&#127995;'],
        ['&#128129;&#127995;', '&#128129,&#127995;'],
        ['&#128130;&#127995;', '&#128130,&#127995;'],
        ['&#128131;&#127995;', '&#128131,&#127995;'],
        ['&#128133;&#127995;', '&#128133,&#127995;'],
        ['&#128134;&#127995;', '&#128134,&#127995;'],
        ['&#128135;&#127995;', '&#128135,&#127995;'],
        ['&#128170;&#127995;', '&#128170,&#127995;'],
        ['&#128372;&#127995;', '&#128372,&#127995;'],
        ['&#128373;&#127995;', '&#128373,&#127995;'],
        ['&#128378;&#127995;', '&#128378,&#127995;'],
        ['&#128400;&#127995;', '&#128400,&#127995;'],
        ['&#128405;&#127995;', '&#128405,&#127995;'],
        ['&#128406;&#127995;', '&#128406,&#127995;'],
        ['&#128581;&#127995;', '&#128581,&#127995;'],
        ['&#128582;&#127995;', '&#128582,&#127995;'],
        ['&#128583;&#127995;', '&#128583,&#127995;'],
        ['&#128587;&#127995;', '&#128587,&#127995;'],
        ['&#128588;&#127995;', '&#128588,&#127995;'],
        ['&#128589;&#127995;', '&#128589,&#127995;'],
        ['&#128590;&#127995;', '&#128590,&#127995;'],
        ['&#128591;&#127995;', '&#128591,&#127995;'],
        ['&#128675;&#127995;', '&#128675,&#127995;'],
        ['&#128692;&#127995;', '&#128692,&#127995;'],
        ['&#128693;&#127995;', '&#128693,&#127995;'],
        ['&#128694;&#127995;', '&#128694,&#127995;'],
        ['&#128704;&#127995;', '&#128704,&#127995;'],
        ['&#128716;&#127995;', '&#128716,&#127995;'],
        ['&#129304;&#127995;', '&#129304,&#127995;'],
        ['&#129305;&#127995;', '&#129305,&#127995;'],
        ['&#129306;&#127995;', '&#129306,&#127995;'],
        ['&#129307;&#127995;', '&#129307,&#127995;'],
        ['&#129308;&#127995;', '&#129308,&#127995;'],
        ['&#129309;&#127995;', '&#129309,&#127995;'],
        ['&#129310;&#127995;', '&#129310,&#127995;'],
        ['&#129311;&#127995;', '&#129311,&#127995;'],
        ['&#129318;&#127995;', '&#129318,&#127995;'],
        ['&#129328;&#127995;', '&#129328,&#127995;'],
        ['&#129329;&#127995;', '&#129329,&#127995;'],
        ['&#129330;&#127995;', '&#129330,&#127995;'],
        ['&#129331;&#127995;', '&#129331,&#127995;'],
        ['&#129332;&#127995;', '&#129332,&#127995;'],
        ['&#129333;&#127995;', '&#129333,&#127995;'],
        ['&#129334;&#127995;', '&#129334,&#127995;'],
        ['&#129335;&#127995;', '&#129335,&#127995;'],
        ['&#129336;&#127995;', '&#129336,&#127995;'],
        ['&#129337;&#127995;', '&#129337,&#127995;'],
        ['&#129341;&#127995;', '&#129341,&#127995;'],
        ['&#129342;&#127995;', '&#129342,&#127995;'],
        ['&#129489;&#127995;', '&#129489,&#127995;'],
        ['&#129490;&#127995;', '&#129490,&#127995;'],
        ['&#129491;&#127995;', '&#129491,&#127995;'],
        ['&#129492;&#127995;', '&#129492,&#127995;'],
        ['&#129493;&#127995;', '&#129493,&#127995;'],
        ['&#129494;&#127995;', '&#129494,&#127995;'],
        ['&#129495;&#127995;', '&#129495,&#127995;'],
        ['&#129496;&#127995;', '&#129496,&#127995;'],
        ['&#129497;&#127995;', '&#129497,&#127995;'],
        ['&#129498;&#127995;', '&#129498,&#127995;'],
        ['&#129500;&#127995;', '&#129500,&#127995;'],
        ['&#129501;&#127995;', '&#129501,&#127995;']
    ]
};
