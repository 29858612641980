/*
    Used on form to copy value (in this case link)
    to clipboard.
 */

$(document).on('click', '.btn-copy-clipboard', function(e) {
    e.preventDefault()

    // Get values from input and dataset
    const targetField = $(this)[0].dataset['target'];
    const field = $(targetField)[0];
    const value = field.value;
    const base = field.dataset['baseUrl']

    // Create a temp input with value to copy
    const $temp = $("<input>");
    $('body').append($temp);
    $temp.val(base + value).select();
    document.execCommand('copy');
    $temp.remove();
});