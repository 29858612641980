(function($R) {

    $R.add('plugin', 'ancre', {
        translations: {
            en: {
                ancre_name: "Anchor",
                ancre_title: "Anchor name"
            },
            fr: {
                ancre_name: "Ancre",
                ancre_title: "Nom de l'ancre"
            }
        },
        modals: {
            "ancre_modal": '<form><div class="form-item"><label for="ancre_modal_title">## ancre_title ##</label><input type="text" name="title" id="ancre_modal_title"/></div></form>'
        },
        onmodal: {
            ancre_modal: {
                open: function ($modal, $form) {
                    var id = $R.dom(this.selection.get().anchorNode.parentNode).attr("id")
                    $form.getField("title").focus();
                    if (typeof id !== "undefined" && id) $form.setData({ title: id })
                },
                insert: function ($modal, $form) {
                    this.insert($form.getData(), $form);
                }
            }
        },
        init: function (app) {
            this.app = app
            this.lang = app.lang
            this.insertion = app.insertion
            this.selection = app.selection
            this.toolbar = app.toolbar
        },
        start: function () {
            var $button = this.toolbar.addButton('ancre', { 
                title: this.lang.get('ancre_name'),
                api: "plugin.ancre.open" 
            })
            $button.setIcon('<i class="fa fa-anchor"></i>')
        },
        open: function () {
            var options = {
                name: "ancre_modal",
                handle: "insert",
                width: "600px",
                commands: {
                    insert: { title: this.lang.get("edit") },
                    cancel: { title: this.lang.get("cancel") }
                }
            };

            this.selection.save()
            this.app.api("module.modal.build", options);
        },
        insert: function (data, $form) {
            if (data.title.trim() === "") $form.setError("title")
            this.selection.restore()
            var selection = this.selection.get()
            $R.dom(selection.anchorNode.parentNode).attr("id", data.title)
            this.app.api("module.modal.close")
        }
    })

})(Redactor)