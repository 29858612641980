(function($R)
{
    $R.add("plugin", "custom_list", {
        translations: {
            en: {
                "custom_list_name": "List",
                "custom_list_list1": "Points list",
                "custom_list_list2": "Arrows list"
            }
        },
        init: function (app) {
            this.app = app;
            this.lang = app.lang;
            this.toolbar = app.toolbar;
            this.insertion = app.insertion;
        },
        start: function()
        {
            var dropdown = {};

    		dropdown.points = { title: this.lang.get('custom_list_list1'), api: 'plugin.custom_list.insert', args: 'points' };
    		dropdown.arrows = { title: this.lang.get('custom_list_list2'), api: 'plugin.custom_list.insert', args: 'arrows' };

            var $button = this.toolbar.addButton('custom_list', { title: this.lang.get('custom_list_name') });
            $button.setIcon('<i class="re-icon-lists"></i>');
			$button.setDropdown(dropdown);
        },
        insert: function (args) {
            if (args === "points") {
                this.insertion.insertHtml('<ul class="list dots"><li></li></ul>')
            } else if (args === "arrows") {
                this.insertion.insertHtml('<ul class="list arrows"><li></li></ul>')
            }
        }
    })
})(Redactor)