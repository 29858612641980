import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"
import 'flatpickr/dist/plugins/monthSelect/style.css'

import { dateOptions, datetimeOptions, statsDateOptions, dateOptionsDefaultToday, monthSelectPluginOptions } from "./config/flatpicker";

document.addEventListener("DOMContentLoaded", function(event) {
    flatpickr(".date-picker", dateOptions)
    flatpickr(".datetime-picker", datetimeOptions)
    flatpickr(".stats-date-picker", statsDateOptions)

});

document.addEventListener("DOMContentLoaded", function(event) {
    flatpickr(".date-picker-month-only", monthSelectPluginOptions)
});