$R.addLang('fr', {
    'table': 'Tableau',
    'insert-table': 'Insérer tableau',
    'insert-row-above': 'Insérer ligne au dessus',
    'insert-row-below': 'Insérer ligne en dessous',
    'insert-column-left': 'Insérer colonne à gauche',
    'insert-column-right': 'Insérer colonne à droite',
    'add-head': 'Ajouter entête',
    'delete-head': 'Supprimer entête',
    'delete-column': 'Supprimer colonne',
    'delete-row': 'Supprimer ligne',
    'delete-table': 'Supprimer tableau',
    'toggle-header': 'Inverser cellule entête'
});