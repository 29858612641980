(function($R) {
  $R.lang['fr'] = {
    "format": "Format",
    "image": "Image",
    "file": "Fichier",
    "link": "Lien",
    "bold": "Gras",
    "italic": "Italique",
    "deleted": "Barré",
    "underline": "Souligné",
    "superscript": "Superscript",
    "subscript": "Subscript",
    "bold-abbr": "B",
    "italic-abbr": "I",
    "deleted-abbr": "S",
    "underline-abbr": "U",
    "superscript-abbr": "Sup",
    "subscript-abbr": "Sub",
    "lists": "Listes",
    "link-insert": "Insérer un lien",
    "link-edit": "Editer le lien",
    "link-in-new-tab": "Ouvrir le lien dans un nouvel onglet",
    "unlink": "Retirer le lien",
    "cancel": "Annuler",
    "close": "Fermer",
    "insert": "Insérer",
    "save": "Sauvegarder",
    "delete": "Supprimer",
    "text": "Texte",
    "edit": "Editer",
    "title": "Titre",
    "paragraph": "Texte normal",
    "quote": "Citation",
    "code": "Code",
    "heading1": "Titre 1",
    "heading2": "Titre 2",
    "heading3": "Titre 3",
    "heading4": "Titre 4",
    "heading5": "Titre 5",
    "heading6": "Titre 6",
    "filename": "Nom",
    "optional": "Optionnel",
    "unorderedlist": "Liste non-ordonnée",
    "orderedlist": "Liste ordonnée",
    "outdent": "Réduire le retrait",
    "indent": "Augmenter le retrait",
    "horizontalrule": "Ligne",
    "upload": "Uploader",
    "upload-label": "Déposer des fichiers ici ou cliquez sur uploader",
    "accessibility-help-label": "Editeur de texte enrichi",
    "caption": "Légende",
    "bulletslist": "Points",
    "numberslist": "Nombres",
    "image-position": "Position",
    "none": "Aucun(e)",
    "left": "Gauche",
    "right": "Droite",
    "center": "Centrer",
    "undo": "Annuler",
    "redo": "Refaire",
    "add-tab": "Ajouter un onglet",
    "remove-tab": "Supprimer l'onglet",
    "table": "Tableau",
    "insert-table": "Insérer tableau",
    "insert-row-above": "Insérer ligne au dessus",
    "insert-row-below": "Insérer ligne en dessous",
    "insert-column-left": "Insérer colonne à gauche",
    "insert-column-right": "Insérer colonne à droite",
    "add-head": "Ajouter entête",
    "delete-head": "Supprimer entête",
    "delete-column": "Supprimer colonne",
    "delete-row": "Supprimer ligne",
    "delete-table": "Supprimer tableau",
    "toggle-header": "Basculer cellulle/entête",
    "video": "Vidéo",
    "video-html-code": "Code d'intégration vidéo ou lien Youtube/Vimeo",
    "accordion": "Accordéon",
    "add": "Ajouter",
    "remove": "Supprimer",
    "content": "Contenu",
    "move_up": "Monter",
    "move_down": "Descendre",
    "hide_content": "Cacher",
    "show_content": "Afficher",
    "slider": "Carousel",
    "choose_image": "Choisir une image",
    "link": "Lien",
    "newtab": "Ouvrir dans un nouvel onglet",
    "readmore": "À lire aussi",
    "readmore-content": "Contenu",
    "googlemaps": "Google maps",
    "longitude": "Longitude",
    "latitude": "Latitude",
    "full_size_map": "Prendre toute la largeur de l'écran ?"
  };
})(Redactor);
