import { Controller } from "stimulus"
import $ from "jquery";
import flatpickr from "flatpickr";
import { options as redactor3_options } from '../plugins/config/redactor3'
import { dateOptions, datetimeOptions } from '../plugins/config/flatpicker';
import { options as select2_options } from '../plugins/config/select2'

export default class extends Controller {
    static targets = ["add_item", "template"]

    add_association(event) {
        event.preventDefault()
        let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
        this.add_itemTarget.insertAdjacentHTML('beforebegin', content)

        // Load dependencies
        // Redactor
        $R('.redactor', redactor3_options);

        // Flatpicker
        // Selector to initialize only not already initialized picker
        flatpickr('.date-picker:not(.flatpickr-input):not(.input)', dateOptions)
        flatpickr('.datetime-picker:not(.flatpickr-input):not(.input)', datetimeOptions)

        // Select2
        // Selector to initialize only not already initialized select2
        $('.select2:not(.select2-hidden-accessible)').select2(select2_options)
    }

    remove_association(event) {
        event.preventDefault()
        let item = event.target.closest(".nested-fields")
        item.querySelector("input[name*='_destroy']").value = 1
        item.style.display = 'none'
    }
}